import React, { useState, createContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const PromotionModalContext = createContext();

export function PromotionModalProvider({ children }) {
	const [open, setModalOpen] = useState(false);
	const [cookies, setCookie, removeCookie] = useCookies(['homeModalOpen']);

	// useEffect(() => {
	// 	!cookies.homeModalOpen && setModalOpen(true);
	// }, []);

	//

	const setOpen = (open) => {
		setCookie('homeModalOpen', 'closed', { maxAge: 86400 });

		setModalOpen(open);
	};

	//on close, set new cookie to expire in 24 hours, check for cookie to set true on mount. If !cookie, set true. If cookie, leave false
	return (
		<PromotionModalContext.Provider value={{ open, setOpen }}>
			{children}
		</PromotionModalContext.Provider>
	);
}

export default PromotionModalContext;
