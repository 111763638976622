exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-terms-js": () => import("./../../../src/pages/api-terms.js" /* webpackChunkName: "component---src-pages-api-terms-js" */),
  "component---src-pages-billing-js": () => import("./../../../src/pages/billing.js" /* webpackChunkName: "component---src-pages-billing-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-list-js": () => import("./../../../src/pages/cookie-list.js" /* webpackChunkName: "component---src-pages-cookie-list-js" */),
  "component---src-pages-dpa-sub-processors-js": () => import("./../../../src/pages/dpa-sub-processors.js" /* webpackChunkName: "component---src-pages-dpa-sub-processors-js" */),
  "component---src-pages-end-of-life-policy-js": () => import("./../../../src/pages/end-of-life-policy.js" /* webpackChunkName: "component---src-pages-end-of-life-policy-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-financial-services-business-builder-visa-js": () => import("./../../../src/pages/financial-services/business-builder-visa.js" /* webpackChunkName: "component---src-pages-financial-services-business-builder-visa-js" */),
  "component---src-pages-financial-services-faq-js": () => import("./../../../src/pages/financial-services/faq.js" /* webpackChunkName: "component---src-pages-financial-services-faq-js" */),
  "component---src-pages-financial-services-index-js": () => import("./../../../src/pages/financial-services/index.js" /* webpackChunkName: "component---src-pages-financial-services-index-js" */),
  "component---src-pages-financial-services-payments-js": () => import("./../../../src/pages/financial-services/payments.js" /* webpackChunkName: "component---src-pages-financial-services-payments-js" */),
  "component---src-pages-general-terms-and-conditions-js": () => import("./../../../src/pages/general-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-general-terms-and-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-equity-js": () => import("./../../../src/pages/private-equity.js" /* webpackChunkName: "component---src-pages-private-equity-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-uc-toolkit-js": () => import("./../../../src/pages/uc-toolkit.js" /* webpackChunkName: "component---src-pages-uc-toolkit-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/Industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

